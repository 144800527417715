import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

const ContactPage = () => (
    <Layout>
        <Seo title={'Contact us | Call & Write To Us | Nemo Personal Finance'} description={'Click here to contact Nemo Personal Finance now.'} lang={'en-gb'} />
        <div className="twoCol wideLeft cfx" id="contactUs">
            <section id="leftCol">
                <div className="inr">
                    <h1 className="hide">Contact us</h1>
                    <div className="inrTwoCol cfx">
                        <div className="top"></div>
                        <div className="mid cfx">
                            <div className="col1 blue">
                                <div className="fb0 cfx">
                                    <div className="bubble blue cfx">
                                        <div className="inr">
                                            <h2>Customer Service</h2>
                                        </div>
                                        <div className="bot"></div>
                                    </div>
                                    <p>If you need to speak to our Customer Services Team:
                                        <br /><strong><br />0800&nbsp;138 2935</strong></p>
                                    <p>
                                        <strong>Call us between: </strong>
                                        <br />
                                    </p>
                                    <p>9:00am - 5:00pm Monday to Friday</p>
                                    <p>
                                        <strong>Email us</strong> <a href="mailto:customerservices@nemoloans.co.uk">customerservices@nemoloans.co.uk</a><br /><br />Please
                                        see our&nbsp;<a href="/privacy-policy">Data Protection page</a> for more
                                        information regarding correspondence by email</p>
                                    <p>Click&nbsp;<a href="/contact-us/complaints">here</a> to see
                                        information&nbsp;about Payment Protection Insurance (PPI)</p>
                                    <p></p>
                                </div>
                                <div className="sep"></div>
                                <div className="fb0 cfx">
                                    <h3>Complaints</h3>
                                    <p>At Nemo Personal Finance, we aim to provide you with the highest standard of
                                        service. However, we recognise that, on occasion, you may feel you have cause
                                        for complaint. If you are unhappy with any aspect of the service you have
                                        received, we would very much like to know.</p>

                                    <p><a href="/contact-us/complaints" title="Find out more" className="btn grey">Find
                                        out more<span className="tl"></span><span className="tr"></span><span
                                            className="bl"></span><span className="br"></span></a></p>
                                </div>
                            </div>
                            <div className="col2 purple">
                                <div className="name cfx">
                                    <div className="bubble purple  cfx">
                                        <div className="inr">
                                            <h2>Help with repayments</h2>
                                        </div>
                                        <div className="bot"></div>
                                    </div>
                                    <p>If you think you may face difficulties making payments, call us on the number
                                        below&nbsp;<br /><strong><br />0800&nbsp;612 9982</strong></p>
                                    <p>
                                        <strong>Call us between:&nbsp;<br /></strong>
                                    </p>
                                    <p>9:00am - 5:00pm Monday to Friday<br /><br /><strong>Email us </strong><a
                                        href="mailto:customermanagement@nemoloans.co.uk">customermanagement@nemoloans.co.uk</a></p>
                                    <p>Please see our&nbsp;<a href="/privacy-policy">Data Protection page</a> for
                                        more information regarding correspondence by email</p>
                                    <p>&nbsp;</p>
                                    <p></p>
                                    {/*<div className="sep"></div>*/}
                                    {/*<h3>Make a payment</h3>*/}
                                    {/*<p className="telNum">If you're an existing Nemo customer and would like to make a*/}
                                    {/*    repayment on your second charge mortgage, you can do so here:</p>*/}
                                    {/*<p><a href="/"*/}
                                    {/*      className="btn round purple">Make a payment<span className="tl"></span><span*/}
                                    {/*    className="tr"></span><span className="bl"></span><span*/}
                                    {/*    className="br"></span></a></p>*/}
                                </div>
                            </div>
                        </div>
                        <div className="bot"></div>
                    </div>
                </div>
            </section>
            <aside id="rightCol">
                <div className="fb2">
                    <div className="top"></div>
                    <div className="mid cfx purple">
                        <h2>General information</h2>
                        {/*<h3>Business enquiries</h3>*/}
                        {/*<p>If you have a query for Nemo which does not regard a second charge mortgage, then please*/}
                        {/*    call:<br /><br />0800 021 7048<br /><strong><br />Call us between:<br /></strong>9am to 5:00pm Monday*/}
                        {/*        to Friday</p>*/}
                        <p>
                            <strong>Write to us</strong> <span className="leading"><strong></strong><br /></span>If you
                            would like to contact us in writing, please use the address below:<br /><br />Nemo Personal Finance
                            Ltd<br />Principality Buildings<br />PO Box 89<br />Queen Street<br />Cardiff<br />CF10 1UA</p>
                        <p></p>
                    </div>
                    <div className="bot"></div>
                </div>
            </aside>
        </div>
    </Layout>
)

export default ContactPage;